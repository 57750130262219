import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import { Button, CircularProgress } from '@material-ui/core';
import qs from 'query-string';

import SEO from '../components/seo';

import * as AccountActions from '../actions/account-actions';

import styles from '../styles/confirm-email.module.scss';

const UnsubscribePage = ({ location, unsubscribeNewsletter }) => {
	const [success, setSuccess] = useState(null);
	const [errors, setErrors] = useState(null);

	const invalidLink = () => {
		setErrors(
			[
				'Sorry, this unsubscribe link is invalid.'
			]
		);
	}

	const init = async () => {
		const { email } = qs.parse(location.search);

		if (!email) {
			invalidLink();
			return;
		}

		const result = await unsubscribeNewsletter(
			{
				email
			}
		);

		if (result.ok) {
			setSuccess(['You have been unsubscribed from our mailing list.']);
			return;
		}


		invalidLink();
	}

	useEffect(() => {
		init();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const Content = () => {
		if (errors) {
			return (
				<ul className={styles.errors}>
					{errors.map((x, i) => (
						<li key={i}>{x}</li>
					))}
				</ul>
			);
		}

		if (success) {
			return (
				<>
					<ul className={styles.success}>
						{success.map((x, i) => (
							<li key={i}>{x}</li>
						))}
					</ul>
					<Button
						type='button'
						variant='contained'
						component={Link}
						to='/'
						color='primary'
						size='large'
					>
						CONTINUE
                    </Button>
				</>
			);
		}

		return (
			<>
				<h5>Unsubscribing...</h5>
				<CircularProgress size={48} />
			</>
		);
	};

	return (
		<>
			<SEO
				title='Unsubscribe'
				path='/unsubscribe/'
				description='Use this link to unsubscribe from our mailing list.'
			/>
			<div className={styles.content}>
				<Content />
			</div>
		</>
	)
};

const mapDispatch = {
	unsubscribeNewsletter: AccountActions.unsubscribeNewsletter
};

export default connect(null, mapDispatch)(UnsubscribePage);
